import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./Header.css";
import logo from "../src/img/logo.svg";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Home from "../pages/Home";
import Landlord from "../pages/Landlord";
import Tenant from "../pages/Tenant";

const Header = () => {
  return (
    <Router>
      <header className="header container">
        <Row>
          <Col className="logo-wrapper">
            <Link to="/">
              <img className="logo" src={logo} />
            </Link>
          </Col>
          <Col className="menu-wrapper">
            <ul>
              <li>
                <Link to="/">About</Link>
              </li>
              <li>
                <Link to="/landlord">Propriétaire</Link>
              </li>
              <li>
                <Link to="/tenant">Locataire</Link>
              </li>
            </ul>
          </Col>
        </Row>
      </header>
      <Switch>
        <Route path="/landlord">
          <Landlord />
        </Route>
        <Route path="/tenant">
          <Tenant />
        </Route>
        <Route path="/">
          <Home />
        </Route>
      </Switch>
    </Router>
  );
};

export default Header;

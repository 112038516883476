import React from "react";
import "./Landlord.css";
import { Col, Row } from "react-bootstrap";
import Header from "../componenents/Header";
import women from "../src/img/landlord-women.svg";
import lampe from "../src/img/lampe.svg";
import man from "../src/img/landlord-men.svg";
import Card from "../componenents/Card";
import pc from "../src/img/pc-icon.svg";
import key from "../src/img/key-icon.svg";
import calander from "../src/img/calander-icon.svg";
import { useFormik } from "formik";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import whiteLogo from "../src/img/white-logo.svg";
import contactIcon from "../src/img/contact-icon.svg";
import firebase from "firebase";
import "firebase/firestore";

const Landlord = () => {
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phone: "",
    },
    onSubmit: (values) => {
      firebase
        .firestore()
        .collection("landlord-contact")
        .add({
          name: values.name,
          email: values.email,
          phone: values.phone,
        })
        .then(
          alert(
            "Votre demande a été envoyer avec succé, vous recevez un appel en moins de 24h de l'un de notre équipe"
          )
        )
        .catch((error) => {
          console.error("Error adding document: ", error);
        });
    },
  });

  return (
    <React.Fragment>
      <div className="intro-wrapper">
        <div className="top-wrapper container notyett-container">
          <p className="big-text">
            Tous les outils pour louer vous-même votre logement.
          </p>
          <p className="small-text">
            Louer votre logement à un étudiant en moins d’une semaine avec un
            procédure 100% digitalisé. Vous bénificier de tous les moyens
            digitales pour louer votre logement en toute sécurité (encaissement
            des loyés, rédaction des contrats, signature éléctronique, envoi des
            quitances de location, visite virtuelle ...). C'est gratuit pour les
            propriétaires des biens!
          </p>
        </div>
        <div className="illustrations-wrappper">
          <img src={women} className="ladlord-women" />
          <img src={lampe} className="ladlord-lampe" />
          <img src={man} className="ladlord-man" />
        </div>
        <div className="cards">
          <div className="cards-wrapper container notyett-container">
            <Row>
              <Col className="card-element">
                <Card
                  icon={pc}
                  title="La recherche des locataires"
                  description="On vous aide pour trouver un locataire on déposant votre annonce sur les plateformes de location."
                />
              </Col>
              <Col className="card-element">
                <Card
                  icon={calander}
                  title="Les démarches de locations"
                  description="On vous offre un ensemble d’outil pour gérer votre location (visite virtuelle, payement des loyers, signature electronique ...)"
                />
              </Col>
              <Col className="card-element">
                <Card
                  icon={key}
                  title="Garantie des loyers impayés"
                  description="Tous nos locataires sont cautioné avec un garantie de 32 mois d’impayé avec une couverture de la dégradation de logement"
                />
              </Col>
            </Row>
          </div>
          <div className="card-line"></div>
        </div>
      </div>

      <div className="contact-form">
        <div className="container notyett-container">
          <h1 className="contact-tilie">Louer votre logement à un étudiant</h1>
          <p className="small-text">
            Remplisser le formulaire suivnat un coneiller vous rappellera par
            téléphone pour étudier votre demande.
          </p>
          <div className="contact-wrapper">
            <form className="contact-form" onSubmit={formik.handleSubmit}>
              <Row>
                <Col className="top-input">
                  <TextField
                    autoComplete="fname"
                    name="NOM & PRÉNOM"
                    variant="outlined"
                    required
                    fullWidth
                    id="Name"
                    label="NOM & PRÉNOM"
                    autoFocus
                    onChange={formik.handleChange("name")}
                    value={formik.values.name}
                  />
                </Col>
                <Col>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="email"
                    label="EMAIL"
                    name="email"
                    autoComplete="email"
                    onChange={formik.handleChange("email")}
                    value={formik.values.email}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="phone"
                    label="NUMÉRO DE TÉLÉPHONE"
                    name="phone"
                    autoComplete="phone"
                    onChange={formik.handleChange("phone")}
                    value={formik.values.phone}
                  />
                </Col>
                <Col>
                  <Button
                    type="submit"
                    fullWidth
                    fullHieght
                    variant="contained"
                    color="primary"
                    className="submit-button"
                  >
                    ENVOYER
                  </Button>
                </Col>
              </Row>
            </form>
          </div>

          <img src={contactIcon} className="contact-icon" />
        </div>
      </div>

      <footer className="foot-wrapper">
        <div className="container notyett-container">
          <Row>
            <Col>
              <p className="footer-text">
                “Tous le monde a le droit à un logement de qualité”
              </p>
            </Col>
            <Col className="footer-logo">
              <img src={whiteLogo} className="footer-logo" />
            </Col>
          </Row>
        </div>
      </footer>
    </React.Fragment>
  );
};

export default Landlord;

import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Header from "./componenents/Header";
import firebase from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyAZtVDZ7de4o1O9ZENvvDM5dxjimZ_xucc",
  authDomain: "notyett-c16a0.firebaseapp.com",
  databaseURL: "https://notyett-c16a0.firebaseio.com",
  projectId: "notyett-c16a0",
  storageBucket: "notyett-c16a0.appspot.com",
  messagingSenderId: "371617254862",
  appId: "1:371617254862:web:546d703321c2abfa83ef9f",
  measurementId: "G-YK3J4NZEDF",
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();

function App() {
  return (
    <div className="app">
      <Header />
      {/*<Intro />*/}
      {/* Home page */}
      {/* Header */}
      {/* Intro */}

      {/* Ladloard/Tenant */}
      {/* Header */}
      {/* Intro */}
      {/* Cards */}
      {/* Contact from */}
      {/* Footer */}
    </div>
  );
}

export default App;

import React from "react";
import Intro from "../componenents/Intro";
import Header from "../componenents/Header";

const Home = () => {
  return (
    <React.Fragment>
      <Intro />
    </React.Fragment>
  );
};

export default Home;

import React from "react";
import { Col, Row } from "react-bootstrap";
import { IntroCTA } from "../componenents/buttons";
import "./Intro.css";
import introImg from "../src/img/body.png";
import head from "../src/img/head.png";
import styled, { keyframes } from "styled-components";
import { bounce } from "react-animations";
import { useHistory } from "react-router-dom";
// rafc

const IntoAnnim = styled.div`
  animation: 6s ${keyframes`${bounce}`} infinite;
`;

const Intro = () => {
  let history = useHistory();
  const handleLandlord = () => {
    history.push("/landlord");
  };

  const handleTenant = () => {
    history.push("/tenant");
  };

  return (
    <div className="intro">
      <div className="intro-wrapper container notyett-container">
        <Row>
          <Col>
            <div className="message-wrapper">
              <p className="big-message">
                Louer un logement étudiant depuis chez vous.
              </p>
              <p className="small-message">
                Trouver un logement de qualité depuis chez vous avec une
                procédure presonnalisé et un suivi aprés la location pour les
                étudiants de Valenciennes.
              </p>
              <IntroCTA
                texte="Louer votre logement"
                backgroundColor="#FF6B81"
                color="white"
                onClick={handleLandlord}
              />
              <IntroCTA
                texte="Trouver un logement"
                backgroundColor="#F4F4F4"
                color="#555555"
                onClick={handleTenant}
              />
            </div>
          </Col>
          <Col className="right-intro">
            <IntoAnnim className="anime-div">
              <img src={head} className="intro-top-image" />
            </IntoAnnim>
            <img src={introImg} className="intro-body-image" />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Intro;

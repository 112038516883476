import React from "react";
import "./Card.css";

const Card = ({ icon, title, description }) => {
  return (
    <div className="card">
      <img src={icon} className="icon" />
      <h1 className="title">{title}</h1>
      <p className="description">{description}</p>
    </div>
  );
};

export default Card;

import React from "react";
import "./Landlord.css";
import { Col, Row } from "react-bootstrap";
import Header from "../componenents/Header";
import women from "../src/img/tenant-women.svg";
import man from "../src/img/tenant-man.svg";
import { useFormik } from "formik";
import Card from "../componenents/Card";
import pc from "../src/img/pc-icon.svg";
import key from "../src/img/key-icon.svg";
import calander from "../src/img/calander-icon.svg";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import whiteLogo from "../src/img/white-logo.svg";
import contactIcon from "../src/img/contact-icon.svg";
import firebase from "firebase";
import "firebase/firestore";

const Tenant = () => {
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phone: "",
    },
    onSubmit: (values) => {
      firebase
        .firestore()
        .collection("tenant-contact")
        .add({
          name: values.name,
          email: values.email,
          phone: values.phone,
        })
        .then(
          alert(
            "Votre demande a été envoyer avec succé, vous recevez un appel en moins de 24h de l'un de notre équipe"
          )
        )
        .catch((error) => {
          console.error("Error adding document: ", error);
        });
    },
  });

  return (
    <React.Fragment>
      <div className="intro-wrapper">
        <div className="top-wrapper container notyett-container">
          <p className="big-text">
            Trouver et louer un logement depuis chez vous.
          </p>
          <p className="small-text">
            Trouver votre logement pour un étudiant en moins d’une semaine avec
            un procédure 100% digitalisé et presonnalisée. Déposer une demande
            de location notre équipe vous proposera des logements avec vos
            critéres. Les loyers proposés incluts les frais de services. Pas
            d'honoraire ni frais de dossier à payer.
          </p>
        </div>
        <div className="illustrations-wrappper">
          <img src={women} className="tenant-women" />
          <img src={man} className="tenant-man" />
        </div>
        <div className="cards">
          <div className="cards-wrapper container notyett-container">
            <Row>
              <Col className="card-element">
                <Card
                  icon={pc}
                  title="Trouver un logement"
                  description="On vous aide à un logement avec une procédure personalisée et 100% digitalisée"
                />
              </Col>
              <Col className="card-element">
                <Card
                  icon={calander}
                  title="Les démarches de locations"
                  description="Visiter le logement à l’aide de notre solution de visite virtuelle et signer le bail avec une signature electronique."
                />
              </Col>
              <Col className="card-element">
                <Card
                  icon={key}
                  title="Services aprés signature du bail"
                  description="Service personalisé aprés la signature du bail pour bénificier des aides au logement et viver en tranquillité dans votre logement."
                />
              </Col>
            </Row>
          </div>
          <div className="card-line"></div>
        </div>
      </div>

      <div className="contact-form">
        <div className="container notyett-container">
          <h1 className="contact-tilie">Trouver un logement pour étudiant</h1>
          <p className="small-text">
            Remplisser le formulaire suivnat un coneiller vous rappellera par
            téléphone pour étudier votre demande.
          </p>
          <div className="contact-wrapper">
            <form className="contact-form" onSubmit={formik.handleSubmit}>
              <Row>
                <Col className="top-input">
                  <TextField
                    autoComplete="fname"
                    name="NOM & PRÉNOM"
                    variant="outlined"
                    required
                    fullWidth
                    id="Name"
                    label="NOM & PRÉNOM"
                    autoFocus
                    onChange={formik.handleChange("name")}
                    value={formik.values.name}
                  />
                </Col>
                <Col>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="email"
                    label="EMAIL"
                    name="email"
                    autoComplete="email"
                    onChange={formik.handleChange("email")}
                    value={formik.values.email}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="phone"
                    label="NUMÉRO DE TÉLÉPHONE"
                    name="phone"
                    autoComplete="phone"
                    onChange={formik.handleChange("phone")}
                    value={formik.values.phone}
                  />
                </Col>
                <Col>
                  <Button
                    type="submit"
                    fullWidth
                    fullHieght
                    variant="contained"
                    color="primary"
                    className="submit-button"
                  >
                    ENVOYER
                  </Button>
                </Col>
              </Row>
            </form>
          </div>

          <img src={contactIcon} className="contact-icon" />
        </div>
      </div>

      <footer className="foot-wrapper">
        <div className="container notyett-container">
          <Row>
            <Col>
              <p className="footer-text">
                “Tous le monde a le droit à un logement de qualité”
              </p>
            </Col>
            <Col className="footer-logo">
              <img src={whiteLogo} className="footer-logo" />
            </Col>
          </Row>
        </div>
      </footer>
    </React.Fragment>
  );
};

export default Tenant;

import React from "react";
import "./Buttons.css";

export const IntroCTA = ({ texte, onClick, backgroundColor, color }) => {
  return (
    <button
      onClick={onClick}
      style={{ color, backgroundColor }}
      className="intro-cta"
    >
      {texte}
    </button>
  );
};
